import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Hero from "../components/Hero/Hero"
import Divider from "../components/Divider/Divider"
import FeaturesSection from "../components/FeaturesSection/FeaturesSection"
import FeatureSection from "../components/FeatureSection/FeatureSection"
import Main from "../components/main"

export const query = graphql`
  {
    companyYaml {
      motto
      shortDescription: short_description
      title
    }
    allServicesYaml(sort: {fields: priority}) {
      edges {
        node {
          title
          service_items 
          logo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    heroLogoImage: file(relativePath: { eq: "logo-white.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    heroBackgroundImage: file(relativePath: { eq: "home-hero-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    aboutImageMWeb: file(relativePath: { eq: "about_bubble_mweb.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    aboutImageDesktop: file(relativePath: { eq: "about_bubble_desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const features = data.allServicesYaml.edges.map(el => {
    const category = el.node
    return {
      title: category.title,
      serviceItems: category.service_items,
      image: category.logo.childImageSharp,
    }
  })

  const siteInfo = data.companyYaml

  return (
    <>
      <SEO title="Home" />
      <Hero
        title={siteInfo.title}
        subtitle={siteInfo.motto}
        image={data.heroLogoImage.childImageSharp}
        backgroundImageName="home"
      />
      <Main>
      <FeaturesSection
        title="Our Services"
        features={features}
        cta={{ title: "Learn More", destination: "/services" }}
      />
      <Divider />
      <FeatureSection
        title="About Wheels On The Bus"
        mwebImage={data.aboutImageMWeb.childImageSharp.fluid}
        desktopImage={data.aboutImageDesktop.childImageSharp.fluid}
        text={siteInfo.shortDescription}
        cta={{ title: "Learn more about us", destination: "/about" }}
        />
        </Main>
    </>
  )
}

export default IndexPage
