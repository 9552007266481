import styled from "styled-components"
import { Link } from "gatsby"

const ButtonLink = styled(Link)`
  color: ${({ theme, $secondary }) => $secondary ? theme.colors.secondaryOrange: theme.colors.primaryBlue };
  border-color: ${({ theme, $secondary }) => $secondary ? theme.colors.secondaryOrange : theme.colors.primaryBlue };
  border-width: 1px;
  border-style: solid;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 1rem;
  display: block;
  margin: 1rem 0;
`


export default ButtonLink;
