import React from "react"
import styled from "styled-components"

const ContentWrapper = styled.div`
  margin: 0 auto;
`

const CardList = styled.ul`
  list-style: disclosure-closed;
  padding: 0 1rem 0 3rem;
  @media (min-width: 768px) {
    padding: 0;
    width: 50%
    margin: 0 auto;
  }
`

const CardListSection = ({ contentItems }) => {
  return (
      <ContentWrapper>
        <CardList>
        { contentItems.map((contentItem, index) => {
            return <li key={index}>{contentItem}</li>
        })
        }
        </CardList>
      </ContentWrapper>
  )
}

export default CardListSection
