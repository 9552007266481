import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import Img from "gatsby-image"
import  ButtonLink  from "../ButtonLink/ButtonLink"

const FeatureSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const ImageWrapper = styled(Img)`
  margin: 0 0 1rem 0;
  width: 100%;
  @media (min-width: 768px) {
    margin: 0 1rem 1rem 0;
    padding: 0;
  }
`

const TextWrapper = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1500px) {
    flex-basis: 100%;
  }
`

const H2 = styled.h2`
  padding-bottom: 1rem;
`

const Blurb = styled.p`
  padding-bottom: 1rem;
`

const FeatureSection = ({ title, mwebImage, desktopImage, text, cta }) => {

  if (mwebImage && !desktopImage) {
      desktopImage = mwebImage;
  }

  const images = [
    mwebImage,
    {
      ...desktopImage,
      media: `(min-width: 768px)`,
    },
  ]
  const ctaLink = cta ? <ButtonLink to={cta.destination} $secondary={true}>{cta.title}</ButtonLink> : null

  return (
    <FeatureSectionWrapper>
      <ImageWrapper durationFadeIn="0" fluid={images} />
      <TextWrapper>
        <H2>{title}</H2>
        <Blurb>{text}</Blurb>
        {ctaLink}
      </TextWrapper>
    </FeatureSectionWrapper>
  )
}

FeatureSection.defaultProps = {
  title: ``,
  text: ``,
  mwebImage: ``,
  desktopImage: ``,
  cta: null,
}

FeatureSection.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  mwebImage: PropTypes.object,
  desktopImage: PropTypes.object,
  cta: PropTypes.shape({
    title: PropTypes.string,
    destination: PropTypes.string,
  })
}

export default FeatureSection
