import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const CardWrapper = styled.div`
  padding: 1rem 0;
`

const ImgWrapper = styled.div`
  max-width: 33%;
  margin: 0 auto;
`

const TextWrapper = styled.div`
  margin: 0 auto;
`

const Title = styled.h4`
  text-align: center;
  margin: 1rem 0;
`

const Card = ({ title, content, image }) => {
  return (
    <CardWrapper>
      <ImgWrapper>
        <Img durationFadeIn="0" fluid={image.fluid} />
      </ImgWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        {content}
      </TextWrapper>
    </CardWrapper>
  )
}

export default Card
