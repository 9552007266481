import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import ButtonLink from "../ButtonLink/ButtonLink"
import Card from "../card"
import CardListSection from "../card_list_section"

const FeaturesSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FeaturesContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: baseline;
  }
`

const FeatureWrapper = styled.div`
    flex: 1 1 0px
    margin: 0 40px;
    flex-grow: 1;
    flex-basis: 0;
`

const H2 = styled.h2`
  align-self: center;
  padding-bottom: 2rem;
`

const ButtonWrapper = styled.div`
align-self: center;
`

const FeaturesSection = ({ title, features, cta }) => {
  const featureCards = features.map((feature, idx) => {
    const contentList = <CardListSection contentItems={feature.serviceItems} />
    return (
      <FeatureWrapper key={idx}>
        <Card
          title={feature.title}
          content={contentList}
          image={feature.image}
        />
      </FeatureWrapper>
    )
  })

  const button = cta ? (
      <ButtonWrapper>
        <ButtonLink to={cta.destination}>
          <h4>{cta.title}</h4>
        </ButtonLink>
      </ButtonWrapper>
  ) : null

  return (
    <FeaturesSectionWrapper>
      <H2>{title}</H2>
      <FeaturesContainer>{featureCards}</FeaturesContainer>
      {button}
    </FeaturesSectionWrapper>
  )
}

FeaturesSection.defaultProps = {
  features: [],
  title: "",
  cta: null,
}

FeaturesSection.propTypes = {
  title: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subheading: PropTypes.string,
      image: PropTypes.object,
    })
  ),
  cta: PropTypes.shape({
    title: PropTypes.string,
    destination: PropTypes.string,
  })
}

export default FeaturesSection
